import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';

import imgSEO from '../../assets/img/heroes/newsletter.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/heroes/thumbnails/website.jpg';
import similar2 from '../../assets/img/heroes/thumbnails/social2.jpg';
import similar3 from '../../assets/img/heroes/thumbnails/mobile.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/envelope.svg';
import contentPic from '../../assets/img/illustrations/newsletter.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Newsletter | Agence digitale | MDS Digital Agency"
      description="MDS Digital Agency est une agence digitale spécialisée dans la création de campagne d’e-mailing. Nous créons des newsletters personnalisées."
      keywords="newsletter, e-mailing"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Newsletter</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Boostez vos ventes et communiquez sur votre entreprise avec des newsletters percutantes.
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={6} className={pageStyle.flex_v_align}>
            <p>
              Pour être présent à tout moment et rappeler à vos clients vos offres et vos produits
              du moment, nous proposons notre solution d'emailing spécialement adaptée à vos
              objectifs.
            </p>
            <p>
              Une newsletter personnalisée, c’est le moyen idéal de rester proche de vos clients et
              de communiquer sur votre actualité. MDS Digital Agency met en page, rédige et envoie
              votre e-mail à l'ensemble de vos contacts.
            </p>
            <p>
              Vous n'avez pas encore de liste de contacts? Faites confiance à MDS pour constituer
              votre base de données et toucher les personnes les plus appropriées grâce à votre
              newsletter.
            </p>
          </Col>
          <Col style={{ paddingLeft: '50px' }} md={6}>
            <img src={contentPic} alt="" />
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Je veux mettre en place une campagne d’e-mailing ? Comment dois-je m’y prendre ? "
        subtitle="Ne restez plus sans réponse"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Site web" url="/services/siteweb/" img={similar1} />
          <SimilarCard text="Réseaux sociaux" url="/services/reseaux-sociaux/" img={similar2} />
          <SimilarCard
            text="Application mobile"
            url="/services/application-mobile/"
            img={similar3}
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
